import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './sidebar.css';
import axios from 'axios';

export default function Sidebar() {
	const [cats, setCats] = useState([]);

	useEffect(() =>{
		const getCats = async () =>{
			const res = await axios.get('/api/category');
			setCats(res.data);
		}
		getCats();
	}, []);

	return (
		<aside className='d-flex flex-column sidebar'>
			<div className='sidebar-about'>
				<h5>Acerca de mi</h5>
				<p>
					Soy desarrollador full stack con más de 4 años de experiencia  en 
					la creación de aplicaciones web. Me considero una persona 
					autodidacta y en constante búsqueda de nuevos conocimientos, además de tener
					un interés especial por la música y el arte en general.
				</p>
			</div>
			<div className='sidebar-categories'>
				<h5>Categorías</h5>
				<ul>
				{
					cats.map((c) =>(
						<Link to={`/blog/?cat=${c.name}`} key={c.name}>
							<li>{c.name}</li>
						</Link>
					))
				}
				</ul>
			</div>
			<div className='sidebar-social'>
				<h5>Redes Sociales</h5>
				<ul className='text-center'>
					<li><a href='https://www.linkedin.com/in/diegomaq/' target='_blank'><FontAwesomeIcon icon={faLinkedinIn}/></a></li>
					<li><a href='https://www.linkedin.com/in/diegomaq/' target='_blank'><FontAwesomeIcon icon={faTiktok}/></a></li>
					<li><a href='https://www.instagram.com/dmusicamx/' target='_blank'><FontAwesomeIcon icon={faInstagram}/></a></li>
				</ul>
			</div>
		</aside>
	);
}