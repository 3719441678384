import { useContext, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../context/Context';
import { UPDATE_ACTION, UPDATE_SUCEESS_ACTION, UPDATE_FAILURE_ACTION } from '../../context/Actions';
import './settings.css';

export default function Settings() {
	const {user, dispatch} = useContext(Context);
	const [file, setFile] = useState(null);
	const [name, setName] = useState(user.name);
	const [lastname, setLastname] = useState(user.lastname);
	const [email, setEmail] = useState(user.email);
	const [password, setPassword] = useState("");
	const [description, setDescription] = useState(user.description);
	const [success, setSuccess] = useState(false);

	const handleSubmit = async (e) =>{
		e.preventDefault();
		dispatch(UPDATE_ACTION());
		const updatedUser = {
			name,
			lastname,
			email,
			description
		};
		if (password != ""){
			updatedUser.password = password;
		}
		if (file) {
			const data = new FormData();
			const filename = Date.now() + '-' + user.username + '-user-picture.' + file.name.split('.').pop();
			data.append('name', filename);
			data.append('file', file);
			updatedUser.profile_picture = filename;
			try {
				await axios.post('/api/file/upload/user', data);
			}
			catch (err) {

			}
		}
		try {
			const res = await axios.put('/api/user/' + user._id, updatedUser, {
				headers: {
					'Authorization': user.token
				}
			});
			setSuccess(true);
			dispatch(UPDATE_SUCEESS_ACTION(res.data));
			//window.location.replace('/');
		}
		catch (err) {
			dispatch(UPDATE_FAILURE_ACTION());
		}
	}

	return (
		<div className='container settings'>
			<form onSubmit={handleSubmit}>
				<div className='settings-picture'>
					<img src={file? URL.createObjectURL(file) : '/images/users/' + user.profile_picture}/>
					<label htmlFor='fileInput'>
						<FontAwesomeIcon icon={faUserPlus} />
					</label>
					<input
						type='file'
						id='fileInput'
						style={{ display: 'none' }} 
						onChange={(e) => setFile(e.target.files[0])}
					/>
				</div>
				<div className='d.flex flex-column'>
					<div className='mb-3'>
						<label htmlFor='inputName' className='form-label'>Nombre</label>
						<input type='text' className='form-control settings-input' id='inputName' placeholder={user.name} onChange={e=>setName(e.target.value)}/>
					</div>
					<div className='mb-3'>
						<label htmlFor='inputLastname' className='form-label'>Apellidos</label>
						<input type='text' className='form-control settings-input' id='inputLastname' placeholder={user.lastname} onChange={e => setLastname(e.target.value)} />
					</div>
					<div className='mb-3'>
						<label htmlFor='inputEmail' className='form-label'>Correo electrónico</label>
						<input type='email' className='form-control settings-input' id='inputEmail' placeholder={user.email} onChange={e => setEmail(e.target.value)} />
					</div>
					<div className='mb-3'>
						<label htmlFor='inputPassword'>Contraseña</label>
						<input type='password' className='form-control' id='inputPassword' onChange={e => setPassword(e.target.value)} />
					</div>
					<div className='mb-3'>
						<label htmlFor='textareaBio'>Descripción</label>
						<textarea className='form-control' placeholder={user.description} id='textareaBio' rows='10' onChange={e=>setDescription(e.target.value)}></textarea>
					</div>
				</div>
				<button type='submit' className='mb-2 btn btn-lg'>Guardar</button>
				<br/>
				{
					success && (
						<span className='text-success'>
							Perfil actualizado correctamente
						</span>
					)
				}
			</form>
		</div>
	);
}