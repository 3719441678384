import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import { Context } from "../../context/Context";
import './article.css';

export default function Article() {
	const {user, dispatch} = useContext(Context);
	const location = useLocation();
	const path = location.pathname.split("/")[2];
	const [post, setPost] = useState([]);
	const [title, setTitle] = useState('');
	const [description, setDesc] = useState('');
	const [updateMode, setUpdateMode] = useState(false);

	useEffect(() =>{
		const getPost = async() =>{
			const res = await axios.get('/api/post/' + path);
			setPost(res.data);
			setTitle(res.data.title);
			setDesc(res.data.description);
		}
		getPost();
	}, [path]);

	const handleUpdate = async() =>{
		try {
			await axios.put('/api/post/' + path, {
				username: user.username, title, description
			},{
				headers: {
					'Authorization': user.token
				}
			});
			window.location.reload();
		}
		catch (err) {}
	}

	const handleDelete = async() =>{
		try {
			await axios.delete('/api/post/' + path, {
				headers: {
					'Authorization': user.token
				}
			});
			window.location.replace('/');
		}
		catch (err) {}
	}

	return(
		<article className='d-flex flex-column single-post'>
		{
			post.picture && (
				<img src={'/images/' + post.picture}/>
			)
		}
			{
				updateMode? <input type='text' className='edit-title-input' placeholder={title} onChange={(e) => setTitle(e.target.value)}/> : 
				<h2 className='single-post-title'>
					{title}
				</h2>
			}
			{
				user != null && (
				post.username == user.username && (
					<div>
						<a className='mx-2 edit' onClick={() => setUpdateMode(true)}><FontAwesomeIcon icon={faEdit}/></a>
						<a className='mx-2 delete' onClick={handleDelete}><FontAwesomeIcon icon={faTrashAlt}/></a>
					</div>
				))
			}
			<p className='single-post-meta d-flex justify-content-between'>
				<Link to={`/blog/?user=${post.username}`}><b>{post.username}</b></Link>< span className='single-post-date'>{new Date(post.createdAt).toLocaleDateString('es-MX', {year: 'numeric', month: 'long', day: 'numeric'})}</span>
			</p>
			{
				updateMode? <textarea className='edit-desc-input' rows='20' value={description} onChange={(e)=>setDesc(e.target.value)}/> :
				<div className='single-post-desc'>
					{parse(description)}
				</div>
			}
			{
				updateMode && (
					<div className='d-flex flex-row-reverse mt-3'><div></div>
						<button className='btn mx-2 btn-update' onClick={handleUpdate}>Actualizar</button>
						<button className='btn mx-2 btn-cancel' onClick={()=>setUpdateMode(false)}>Cancelar</button>
					</div>
				)
			}
		</article>
	);
}
