import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import './footer.css';

export default function Footer() {
	return(
		<div className='bg-dark footer'>
			<footer className='container'>
				<div className='row'>
					<div className='col-md-4 py-4 px-4'>
						<h5>Sitio</h5>
						<ul className='nav flex-column'>
							<li><Link to='/'>Inicio</Link></li>
							<li><Link to='/'>Blog</Link></li>
							<li><Link to='/'>Acerca de</Link></li>
							<li><Link to='/'>Contacto</Link></li>
						</ul>
					</div>
					<div className='col-md-4 py-4 px-4'>
						<h5>Contacto</h5>
						<p>Si tienes alguna duda o comentario, puedes enviarla al siguiente correo electrónico <span className='text-white'>contacto@diegomaq.com</span></p>
					</div>
					<div className='col-md-4 py-4 px-4'>
						<h5>Youtube</h5>
						<p>¿Quieres escuchar mi proyecto musical? Suscríbete a mi canal de <a href='https://www.youtube.com/@dmusicamx' target='_blank' className='text-white'>YouTube <FontAwesomeIcon className='footer-icon' icon={faYoutube}/></a></p>
					</div>
				</div>
				<div className="d-flex justify-content-center py-4 border-top copyright">
					<p>&copy; 2023. Diego Martínez Maqueda</p>
				</div>
			</footer>
		</div>
	);
}