import './header.css';

export default function Header() {
	return(
		<section className='container py-5 mb-5 text-center header'>
			<div className='row py-lg-5'>
				<div className='col-lg-6 col-md-8 mx-auto'>
					<h1>DIEGO MAQ</h1>
					<p>Ciencia & Tecnología</p>
				</div>
			</div>
		</section>
	);
}