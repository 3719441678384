export const LOGIN_ACTION = () =>({
	type: 'LOGIN_PENDING'
});

export const LOGIN_SUCCESS_ACTION = (user) =>({
	type: 'LOGIN_SUCCESS',
	payload: user
});

export const LOGIN_FILURE_ACTION = () =>({
	type: 'LOGIN_FAILURE'
});

export const LOGOUT_ACTION = () =>({
	type: 'LOGOUT'
});

export const UPDATE_ACTION = () =>({
	type: 'UPDATE_PENDING'
});

export const UPDATE_SUCEESS_ACTION = (user) => ({
	type: 'UPDATE_SUCCESS',
	payload: user
});

export const UPDATE_FAILURE_ACTION = () =>({
	type: 'UPDATE_FAILURE'
});