import { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import './topbar.css';

export default function Topbar() {
	const {user, dispatch} = useContext(Context);

	const handleLogout = () =>{
		dispatch({type: 'LOGOUT'});
	};
	
	return(
		<div className='topbar p-3 bg-dark text-white'>
			<div className='container'>
				<div className='d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between'>
					<Link to='/'><span>D Maq</span></Link>
					<ul className='nav'>
						<li className='nav-link text-white'>
							<Link to='/'>Inicio</Link>
						</li>
						<li className='nav-link text-white'>
							<Link to='/blog'>Blog</Link>
						</li>
						<li className='nav-link text-white'>
							<Link to='/about'>Acerca de</Link>
						</li>
						<li className='nav-link text-white'>
							<Link to='/contact'>Contacto</Link>
						</li>
						{
							user? (<li className='nav-link topbar-dropdown'>
								<div className='dropdown'>
									<img src={'/images/users/'+ user.profile_picture} id='dropdownProfile' data-bs-toggle='dropdown' aria-expanded='false'/>
									<ul className="dropdown-menu" aria-labelledby="dropdownProfile">
										<li className='dropdown-item'><Link to='/write'>Nuevo post</Link></li>
										<li className='dropdown-item'><Link to='/settings'>Opciones</Link></li>
										<li className='dropdown-item' onClick={handleLogout}><Link to="/">Cerrar sesión</Link></li>
									</ul>
								</div>
							</li>) : null
						}
					</ul>
				</div>
			</div>
		</div>
	);
}