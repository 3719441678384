import { useContext, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import { Context } from '../../context/Context';
import './write.css';

export default function Write(){
	const [title, setTitle] = useState("");
	const [cats, setCats] = useState("");
	const [description, setDesc] = useState("");
	const [file, setFile] = useState(null);
	const {user} = useContext(Context);

	const handleSubmit = async (e) =>{
		e.preventDefault();
		const newPost = {
			username: user.username,
			title,
			description
		};
		
		if(cats != ""){
			let categories = [];
			cats.toUpperCase().split(';').map(c =>{
				categories.push({name: c});
			});
			newPost.categories = categories;
			try {
				await axios.post('/api/category', categories, {
					headers: {
						'Authorization': user.token
					}
				});
			}
			catch (err) {

			}
		}
		if (file) {
			const data = new FormData();
			const filename = Date.now() + '-' + title + '.' + file.name.split('.').pop();
			data.append('name', filename);
			data.append('file', file);
			newPost.picture = filename;
			try {
				await axios.post('/api/file/upload/post', data);
			}
			catch (err) {

			}
		}
		try {
			const res = await axios.post('/api/post', newPost, {
				headers: {
					'Authorization': user.token
				}
			});
			window.location.replace('/post/' + res.data._id);
		}
		catch (err) {
			
		}
	}

	return(
		<div className='container write'>
		{
			file && <img src={URL.createObjectURL(file)} className='mb-5'/>   
		}
			<form className='write-form' onSubmit={handleSubmit}>
				<div className='row'>
					<div className='col-12'>
						<div className='d-flex align-items-center mb-2'>
							<label htmlFor='fileInput'>
								<FontAwesomeIcon icon={faPlusSquare} className='write-icon'/>
							</label>
							<input type='file' id='fileInput' style={{display: 'none'}} onChange={(e) => setFile(e.target.files[0])}/>
							<input type='text' placeholder='Título' className='px-3 write-input' autoFocus={true} onChange={(e) => setTitle(e.target.value)}/>
							<button type='submit' className='btn btn-lg'>Publicar</button>                            
						</div>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12'>
						<input type='text' placeholder='CATEGORIAS (separadas por ;)' className='write-input write-cat' onChange={(e) => setCats(e.target.value)}/>
					</div>
				</div>
				<div className='row'>
					<div className='col-12'>
						<div className='d-flex align-items-center mb-2'>
							{/* <textarea type='text' placeholder='Contenido...' className='write-input write-text' rows='20' onChange={(e) => setDesc(e.target.value)}></textarea> */}
							<CKEditor
								editor={Editor}
								config={
									{
										codeBlock: {
											languages: [
												{ language: 'plaintext', label: 'Plain text', class: '' },
												{ language: 'c', label: 'C' },
												{ language: 'cs', label: 'C#' },
												{ language: 'cpp', label: 'C++' },
												{ language: 'css', label: 'CSS' },
												{ language: 'diff', label: 'Diff' },
												{ language: 'html', label: 'HTML' },
												{ language: 'java', label: 'Java' },
												{ language: 'javascript', label: 'JavaScript' },
												{ language: 'php', label: 'PHP' },
												{ language: 'python', label: 'Python' },
												{ language: 'ruby', label: 'Ruby' },
												{ language: 'typescript', label: 'TypeScript' },
												{ language: 'xml', label: 'XML' }
											]
										}
									}
								}
								onChange={(event, editor)=>{
									setDesc(editor.getData())
								}}
								className='w-100'
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}