import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar";
import Posts from "../../components/posts/Posts";
import Pagination from '../../components/pagination/Pagination';
import './blog.css';

export default function Blog() {
	const [posts, setPosts] = useState([]);
	const [pages, setPages] = useState(1);
	const {search} = useLocation();

	useEffect(() =>{
		const fetchPosts = async ()=>{
			const res = await axios.get('/api/post' + search);
			setPosts(res.data.posts);
			setPages(res.data.pages);
		}
		fetchPosts();
	}, [search]);

	return(
		<div className='container blog'>
			<h2>Léeme</h2>
			<div className='row'>
				<div className='col-md-8'>
					<Posts posts={posts}/>
					<Pagination pages={pages}/>
				</div>
				<div className='col-md-4'>
					<Sidebar/>
				</div>
			</div>
		</div>
	);
}