import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import Topbar from './components/topbar/Topbar';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import Single from './pages/single/Single';
import Blog from './pages/blog/Blog';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Login from './pages/login/Login';
import Write from "./pages/write/Write";
import Settings from './pages/settings/Settings';
import Foryou from './pages/foryou/Foryou';
import { useContext } from 'react';
import { Context } from './context/Context';
function App() {
	const {user} = useContext(Context);
    return (
		<div className='App'>
			<BrowserRouter>
				<Topbar/>
				<Routes>
					<Route path='/' element={<Home/>}/>
					<Route path="/post/:id" element={<Single/>}/>
					<Route path="/blog" element={<Blog/>}/>
					<Route path='/about' element={<About/>}/>
					<Route path='/contact' element={<Contact/>}/>
					<Route path='/login' element={user? <Home /> : <Login/>}/>
					<Route path="/write" element={!user ? <Home /> : <Write/>}/>
					<Route path="/settings" element={!user ? <Home /> : <Settings/>}/>
					<Route path="/foryou" element={<Foryou/>}/>
				</Routes>
				<Footer/>
			</BrowserRouter>
		</div>
    );
}

export default App;