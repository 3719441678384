import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Posts from "../../components/posts/Posts";
import './home.css';

export default function Home() {
    const [posts, setPosts] = useState([]);

    useEffect(() =>{
        const fetchPosts = async ()=>{
            const res = await axios.get('/api/post');
            setPosts(res.data.posts);
        }
        fetchPosts();
    }, []);

    return(
        <main>
            <Header/>
            <div className='container home'>
                <div className='row'>
                    <div className='col-md-8'>
                        <Posts posts={posts}/>
                    </div>
                    <div className='col-md-4'>
                        <Sidebar/>
                    </div>
                </div>
            </div>
        </main>
    );
}