import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Sidebar from "../../components/sidebar/Sidebar";
import './contact.css';

export default function Contact() {
	return(
		<div className='container contact'>
			<h2>Ecríbeme</h2>
			<div className='row'>
				<div className='col-md-8'>
					<p>Si tienes alguna duda, comentario o tan solo deseas contactarte conmigo, puedes escribirme al 
						siguiente correo electrónico.</p>
					<p><FontAwesomeIcon icon={faEnvelope}/> contacto@diegomaq.com</p>
				</div>
				<div className='col-md-4'>
					<Sidebar/>
				</div>
			</div>
		</div>
	);
}