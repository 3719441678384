import Article from '../../components/article/Article';
import Sidebar from '../../components/sidebar/Sidebar';
import './single.css';

export default function Single() {
	return (
		<div className='container single'>
			<div className='row'>
				<div className='col-md-8'>
					<Article/>
				</div>
				<div className='col-md-4'>
					<Sidebar/>
				</div>
			</div>
		</div>
	);
}