import Post from '../post/Post';
import './posts.css';

export default function Posts({posts}) {
	return (
		<div className='d-flex flex-column posts'>
		{
			posts.map(p =>(
				<Post post={p} key={p._id}/>
			))
		}
		</div>
	);
}