import './about.css';

export default function About() {
    return(
        <div className='container about'>
			<h2>Conóceme</h2>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='mb-2 mt-3 text-center'>
                        <img className='sidebar-picture' src='/images/users/diegomaq-user-picture.jpg'/>
                    </div>
                </div>
                <div className='col-md-8'>
                    <h3>Diego Martínez</h3>
                    <p>
                        Soy desarrollador full stack con más de 4 años de experiencia en la creación e implementación de aplicaciones web y 
                        de escritorio, trabajando principalmente con las tecnologías de .NET, Node.js y React.js. Me desempeño como una 
                        persona autónoma y adaptable a los cambios, en constante búsqueda de nuevos conocimientos. Actualmente me encuentro 
                        aprendiendo temas relacionados al análisis de datos, machine learning e inteligencia artificial con Python.
                    </p>
                    <p>
                        Además de lo anterior mencionado, tengo interés por el arte, especialmente por la música, por lo que ocasionalmente 
                        realizo interpretaciones de algunas canciones y temas musicales, además de estar empezando a trabajar en composiciones 
                        propias.
                    </p>
                </div>
            </div>
        </div>
    );
}