import './foryou.css';

export default function Foryou() {
    return(
        <div className='container'>
            <div className='flex'>
                <div class="word">
                    <div class="Z">
                        <div class="z-top"></div>
                        <div class="z-bottom"></div>	
                    </div>
                    <div class="A">
                        <div class="a-top"></div>
                        <div class="a-bottom"></div>
                    </div>
                    <div class="C">
                        <div class="c-top"></div>
                        <div class="c-bottom"></div>
                    </div>
                    <div class="H">
                        <div class="h-all"></div>
                    </div>
                </div>
            </div>
                

        </div>
    );
}