import { useRef, useContext } from 'react';
import { Context } from '../../context/Context';
import axios from 'axios';
import './login.css';

export default function Longin() {
	const userRef = useRef();
	const passwordRef = useRef();
	const {dispatch, isFetching, error} = useContext(Context);

	const handleSubmit = async (e) =>{
		e.preventDefault();
		dispatch({type: 'LOGIN_PENDING'});
		try {
			const res = await axios.post('/api/user/login', {
				username: userRef.current.value,
				password: passwordRef.current.value
			});
			dispatch({type: 'LOGIN_SUCCESS', payload: res.data});
		}
		catch (err){
			dispatch({type: 'LOGIN_FAILURE'});
		}
	};

	return(
		<div className='container text-center login'>
			<form className='d-flex flex-column pt-5 login-form' onSubmit={handleSubmit}>
				<h2 className='mb-3'>Diego Maq</h2>
				<div className="form-floating mb-2">
					<input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" ref={userRef}/>
					<label htmlFor="floatingInput">Correo electrónico</label>
				</div>
				<div className="form-floating mb-2">
					<input type="password" className="form-control" id="floatingPassword" placeholder="Password" ref={passwordRef}/>
					<label htmlFor="floatingPassword">Contraseña</label>
				</div>
				<button className="w-100 btn btn-lg" type='submit' disabled={isFetching}>Iniciar sesión</button>
				<br/>
				<p>{error? 'Correo electrónico o contraseña incorrecta' : ''}</p>
			</form>
		</div>
	);
}