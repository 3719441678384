import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './pagination.css';

export default function Pagination({pages}){
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();
	const pagesArray = [];

	useEffect(() =>{
		setPage(searchParams.get('page') == null? 1 : searchParams.get('page'));
	}, [searchParams]);

	pagesArray.push(<li key={0} className={'page-item' + (page == 1? ' disabled' : '')}>
		<a className='page-link' 
			onClick={()=> {
				searchParams.set('page', Number(page) - 1); 
				setSearchParams(searchParams.toString());
			}}
		>Anterior</a>
	</li>);
	for(let i = 1; i <= pages; i++){
		pagesArray.push(<li key={i} className={'page-item'  + (i == page? ' active' : '')}>
			<a className='page-link' 
				onClick={()=> {
					searchParams.set('page', i); 
					setSearchParams(searchParams.toString());
				}}
			>{i}</a>
		</li>);
	}
	pagesArray.push(<li key={Number(pages) + 1} className={'page-item' + (page == pages? ' disabled' : '')}>
		<a className='page-link' 
			onClick={()=> {
				searchParams.set('page', Number(page) + 1); 
				setSearchParams(searchParams.toString());
			}}
		>Siguiente</a>
	</li>);

	return (
		<nav className='navigation'>
			<ul className='pagination'>
			{
				pagesArray
			}    
			</ul>
		</nav>
	);

}