import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import './post.css';

export default function Post({post}) {
	return(
		<div className='post row'>
			{
				post.picture && (
					<div className='col-lg-5'>
						<img src={'/images/' + post.picture}/>
					</div>
				)
			}
			<div className='col-lg-7'>
				<Link to={`/post/${post._id}`}>
					<h3 className='post-title'>{post.title}</h3>
				</Link>
				<p className='post-meta'>{new Date(post.createdAt).toLocaleDateString('es-MX', {year: 'numeric', month: 'long', day: 'numeric'})}</p>
				{
					post.categories.map(c =>(
						<span className='post-cat' key={c.name}>{c.name} | </span>
					))
				}
				<div className='post-desc'>{parse(post.description)}</div>
			</div>
		</div>
	);
}